/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
import LCFQheader from "../LCFQheader";
import MyTable from "../../common/Fwhgypcg/MyTable";
import AddwpInfo from "../../common/Fwhgypcg/AddwpInfo";
import { RxUtil } from "@/assets/util.js";
import { timesFnt, unique, format, formatHMT } from "@/assets/app.js";
export default {
  name: "lcfqYpcg",
  components: {
    LCFQheader,
    MyTable,
    AddwpInfo
  },
  data() {
    return {
      oneft: "/index",
      headTit: "新建申请",
      tit: "服务和供应品采购",
      showDataCG: false,
      showsyqck: false,
      minDate: new Date(2020, 0, 1),
      currentDate: new Date(),
      showListpage: false,
      showGinfo: false,
      userID: "",
      solId: "",
      actDefId: "",
      resultFa: [],
      tremListId: [],
      cgwpDatas: [],
      //选中的子项目
      wpInfoData: {},
      datas: {
        cgsqdbh: "",
        cgsqr: "",
        cgsqrbm: "",
        bz: ''
      },
      cgsqrT: new Date()
    };
  },
  created() {
    this.cgORxj();
    this.initBySolInstId();
  },
  methods: {
    initBySolInstId() {
      let solId = this.$route.params.solId;
      var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId;
      this.$ajax.post(url).then(response => {
        this.solId = response.data.bpmSolution.solId;
        this.actDefId = response.data.bpmSolution.actDefId;
      });
      let urlBh = _baseUrl + "/sys/core/sysSeqId/genNo_cgsqd_bh.do?_=1645078051915";
      this.$ajax.post(urlBh).then(res => {
        // let cgsqdbh = res.data;
        this.datas.cgsqdbh = res.data;
      });
      let userId = {
        PARTY2_: RxUtil.getCache("userID")
      };
      let formData = new FormData();
      formData.append("params", JSON.stringify(userId));
      let urlBm = _baseUrl + "/sys/db/sysSqlCustomQuery/queryForJson_hqbm.do";
      this.$ajax.post(urlBm, formData).then(res => {
        this.datas.cgsqrbm = res.data.data[0].NAME_;
      });
    },
    async cgORxj() {
      let busad = this.$route.params.busad;
      let instId = this.$route.params.instId;
      console.log('instId = ' + instId);
      if (instId != 0) {
        var this_ = this;
        console.log('this.instId!=0....');
        this.headTit = "我的草稿";
        this.oneft = "/myDrafts";
        //这个页面需要这两个接口来取它的草稿数据。
        var url = _baseUrl + "/servicegyp/fwgypcgsqg/fwGypCgSqg/getJson.do?ids=" + busad;
        this.$ajax.post(url).then(res => {
          console.log("getJson.do?=", res);
          this_.datas.bz = res.data.bz;
          this_.datas.cgsqrbm = res.data.cgbm;
          this_.datas.cgsqr = res.data.cgsqr;
          this_.datas.cgsy = res.data.cgsy;
          this_.datas.cgrq = this_.dateToTime(res.data.cgrq);
          this_.datas.cgdzj = res.data.totalprice;
        });
        var url = _baseUrl + "/servicegyp/fwgypcgdetail/fwGypCgDetail/listData.do?Q_CGID_S_LK=" + busad;
        this.$ajax.post(url).then(res => {
          console.log("listData.do?=", res.data.data);
          var itemArr = res.data.data;
          this.cgwpDatas = new Array(itemArr.length);
          for (var i = 0; i < itemArr.length; i++) {
            this.cgwpDatas[i] = {};
            this.cgwpDatas[i].fwgypmc = itemArr[i].fwgypmc;
            this.cgwpDatas[i].createBy = itemArr[i].createBy;
            this.cgwpDatas[i].createTime = itemArr[i].createTime;
            this.cgwpDatas[i].ggxh = itemArr[i].ggxh;
            this.cgwpDatas[i].identifyLabel = itemArr[i].identifyLabel;
            this.cgwpDatas[i].id = itemArr[i].id;
            this.cgwpDatas[i].tenantId = itemArr[i].tenantId;
            this.cgwpDatas[i].pkId = itemArr[i].pkId;
            this.cgwpDatas[i].sqsl = itemArr[i].sqsl;
            this.cgwpDatas[i].price = itemArr[i].price;
            this.cgwpDatas[i].zt = itemArr[i].zt;
            this.cgwpDatas[i].dgdw = itemArr[i].dgdw;
          }
          console.log(this.cgwpDatas);
        });
        //拿到的草稿数据要进行组装
      } else {}
      this.getUser();
    },
    //获取用户信息
    getUser() {
      this.datas.cgsqr = RxUtil.getCache("userName");
    },
    onConfirmCG(timestamp) {
      this.datas.cgrq = format(timestamp);
      this.cgsqrT = formatHMT(timestamp);
      this.showDataCG = false;
    },
    dateToTime(cTime) {
      let date = new Date(cTime);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      date = year + '-' + month + '-' + day;
      console.log(date); // 2018-10-09
      return date;
    },
    showList() {
      this.tremListId = this.cgwpDatas;
      this.showListpage = true;
    },
    cgwpList(list) {
      this.cgwpDatas = list;
      console.log('增加了条目=', this.cgwpDatas);
      this.showListpage = false;
    },
    //查看具体信息
    goodsInfo(item) {
      this.showGinfo = true;
      this.wpInfoData = item;
      console.log(item, this.wpInfoData);
    },
    //添加信息
    wpListInfo(item) {
      this.cgwpDatas.map(datai => {
        if (datai.pkId == item.pkId) {
          datai = item;
        }
      });
      this.showGinfo = false;
    },
    //删除
    delectWP() {
      console.log(this.resultFa); //选中的要删的
      console.log(this.cgwpDatas); //现有的
      for (let i = 0; i < this.resultFa.length; i++) {
        for (let y = 0; y < this.cgwpDatas.length; y++) {
          if (this.resultFa[i] == this.cgwpDatas[y].pkId) {
            this.cgwpDatas.splice(y, 1);
          }
        }
      }
      this.$refs.checkboxGroup.toggleAll(false);
    },
    upDatas() {
      if (this.cgwpDatas.sqsl != "" && this.cgwpDatas.price != "" && this.datas.cgrq != "" && this.datas.cgsy != "") {
        console.log(this.cgwpDatas);
        let sumtotal = 0;
        for (let i = 0; i < this.cgwpDatas.length; i++) {
          sumtotal += Number.parseFloat(this.cgwpDatas[i].total).toFixed(2);
          this.cgwpDatas[i].createTime = formatHMT(this.cgwpDatas[i].createTime);
          this.cgwpDatas[i].sysj = formatHMT(this.cgwpDatas[i].createTime);
          this.cgwpDatas[i].basicid = this.cgwpDatas[i].pkId;
          this.cgwpDatas[i]._id = i + 1;
          this.cgwpDatas[i]._uid = i + 1;
        }
        var jsonDataObj = {
          // bos:[{
          //   boDefId:this.boDefId,
          //   formKey:this.formKey,
          //   data:{
          id: "",
          cgdbh: this.datas.cgsqdbh,
          // cgsqr: this.cgsqrT,
          cgsqr: this.datas.cgsqr,
          cgbm: this.datas.cgsqrbm,
          cgrq: this.cgsqrT,
          //this.datas.cgrq,
          cgsy: this.datas.cgsy,
          bz: this.datas.bz,
          // sumtotal:sumtotal,
          //sumtotal:"",
          totalprice: this.datas.cgdzj,
          SUB_datagrid1: this.cgwpDatas
          //   }
          // }]
        };

        var jsonData = JSON.stringify(jsonDataObj);
        var params = new FormData();
        params.append("solId", this.solId);
        params.append("bpmInstId", "");
        params.append("jsonData", jsonData);
        return params;
      } else {
        this.errorToast("物品信息未完善！");
      }
    },
    //字段检查
    iN(str) {
      if (str == '' || str == null || typeof str == 'undefined') {
        return true;
      }
      return false;
    },
    //整体判断
    inspect() {
      if (this.iN(this.datas.cgsqr) || this.iN(this.datas.cgsqrbm) || this.iN(this.datas.cgsy) || this.iN(this.datas.cgrq)) {
        return true;
      }
      return false;
    },
    //添加的子数据判断
    ziInspect() {
      if (this.cgwpDatas.length == 0) {
        return true;
      } else {
        var money = 0;
        for (var i of this.cgwpDatas) {
          money += parseFloat(i.total);
        }
        this.datas.cgdzj = money + "";
        console.log(this.datas.cgdzj);
      }
      return false;
    },
    onSubmit() {
      if (this.ziInspect()) {
        this.errorToast("启动失败！请添加采购物品", "1800");
        return;
      }
      if (this.inspect()) {
        this.errorToast("启动失败！请检查信息是否填写完整", "1800");
        return;
      }
      var params = this.upDatas();
      var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
      this.$dialog.loading.open('正在启动流程,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("启动成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败！请检查信息是否填写完整", "1800");
        }
      });
    },
    onPreservation() {
      if (this.ziInspect()) {
        this.errorToast("启动失败！请添加采购物品", "1800");
        return;
      }
      if (this.inspect()) {
        this.errorToast("保存失败！请检查信息是否填写完整", "1800");
        return;
      }
      var params = this.upDatas();

      // console.log(params);
      //return;

      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败！请检查信息是否填写完整", "1800");
        }
      });
    },
    onLct() {
      console.log(this.actDefId);
      this.$router.push({
        name: "procImage",
        params: {
          id: this.actDefId,
          type: 'start'
        }
      });
    }
  }
};